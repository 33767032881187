body.prescriptions.show {

  .container-fluid.main-container {
    max-width: 900px;

    .prescription-header h4 {
      min-height: 35px;
    }
  }

  @media (max-width: 576px) {
    .btn.btn-primary.pull.right.print-media-scr.btn-lg {
      float: right !important;
    }
  }
}

p.miscellaneous_notes_paragraph {
  margin-bottom: 0;

  br {
    margin-bottom: 0.5rem;
  }
}
