body.orders.show, body.orders.begin_consultation, body.orders.view {

  .container-fluid.main-container {
    max-width: 900px;
  }

  #modal-window {
    padding-left: 15px;
  }

  #modal-window .modal-dialog {
    max-width: 1440px !important;

    table td ul {
      padding-inline-start: 1rem;
    }
  }

  .highlighted {
    background-color: yellow;
  }

  #prescription-select li.optgroup-1 .opt.dropdown-item {
    font-weight: bold;
    color: blue;
  }

  .dropdown-menu.show {
    font-size: 1.05em;
  }

  small a {
    font-size: 80%;
  }
}

body.orders.index {
  th, td.align-middle {
    width: 16.6%;

    a.btn.btn-primary,
    a.btn.btn-success {
      width: 100%
    }
  }
}
