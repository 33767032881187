html {
  font-size: 14px;
}

.wrapper {
  background-color: #F0F2F7;
  display: flex;
  width: 100%;
  align-items: stretch;
  perspective: 1500px;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #F0F2F7;

  /* -----SIDEBAR STYLE----------- */

  #sidebar {
    min-width: 250px;
    max-width: 250px;
    color: #fff;
    background: #2b4969;
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    transform-origin: bottom left;
    font-family: Source Sans Pro Regular;
    a,
    a:hover,
    a:focus {
      color: inherit;
      text-decoration: none;
      transition: all 0.3s;
    }
    .sidebar-header {
      padding: 20px;
      background: #2b4969;
    }
    .admin-name {
      padding: 20px;
      background: #0F1941;
    }
    ul li a {
      text-align: left;
      padding: 10px;
      padding-left: 20px;
      font-size: 1rem;
      display: block;
      font-size: 15px;
    }
    ul li a:hover {
      color: #fff;
      background: #15425A;
    }

    ul li .active {
      color: #fff;
      background: #15425A;
      border: 2px solid #ced4da;
      border-top: none;
      border-right: none;
      border-bottom: none;
    }
    a[data-toggle='collapse'] {
      position: relative;
    }
    .dropdown-toggle::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
    ul ul a {
      font-size: 1em;
      padding-left: 70px !important;
      background: #0F1941;
      font-size: 15px;
    }
  }
}

/* -------CONTENT STYLE---------*/

#content {
  width: 100%;
  background:#fbfbfb !important;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  transition: all 0.3s;
  .container-fluid {
    padding-left: 0px;
    font-size: 15px;
  }
}

.content-body {
  padding: 0 15px;
  margin-top: 15px;
}

#sidebar-collapse {
  width: 40px;
  height: 40px;
  background: #f8f8f8;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

#sidebar-collapse span {
  width: 100%;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #7386D5;
  margin-left: -7px;
  transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
  transition-delay: 0.2s;
}

#sidebar-collapse span:first-of-type {
  transform: rotate(45deg) translate(2px, 2px);
}

#sidebar-collapse span:nth-of-type(2) {
  opacity: 0;
}

#sidebar-collapse span:last-of-type {
  transform: rotate(-45deg) translate(1px, -1px);
}

#sidebar.active {
  margin-left: -250px;
  transform: rotateY(100deg);
}

#sidebar-collapse.active span {
  transform: none;
  opacity: 1;
  margin: 2px auto;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.navbar {
  padding: 10px 10px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 0;
  margin-bottom: 0px;
}

.header-profile img {
  border-radius: 50%;
  margin: -1px;
  overflow: hidden;
  position: relative;
  height: 30px;
  width: 30px;
}

#dashboard-header {
  background-color: #0074D1;
  color: #fff;
}