/*
*= require_tree .
*/

@import "bootstrap";
@import "bootstrap-table";
@import "bootstrap-datepicker";
@import "bw";
@import "component-chosen";
// $fa-font-path: "~font-awesome/fonts/";
// @import "~font-awesome/scss/font-awesome";
// $fa-font-path: "/node_modules/font-awesome/fonts/";
// $fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
// @import "@fortawesome/fontawesome-free/css/all";
// @import 'intl-tel-input/build/css/intlTelInput.css';
@import "admin";
@import "admin/layout";
@import "users";
@import "doctors/billings";
@import "doctors/dashboard";
@import "doctors/orders";
@import "doctors/prescriptions";
@import "doctors/message_templates";
@import "pharmacists/medications/edit";
@import "pharmacists/order_items/show";
@import "pharmacists/new";
@import "media_query";
@import "bootstrap-select/dist/css/bootstrap-select.css";
@import "./vendors/fullcalendar/fullcalendar";
@import "tooltipster/dist/css/tooltipster.bundle.min";
@import "tooltipster/src/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-noir";
@import "tooltips/rugiet";
// @import 'wice_grid'; # Not available for rails 6

.signature_pad {
  margin: 0 auto;
}

.signature_pad_body canvas {
  width: 400px;
  height: 120px;
  border: dotted #444;
  background: #cccccc;
}
.signature_pad_body {
  width: 300px;
  height: 100px;
}
.hide {
  display: none;
}
.blue-color {
  color: blue;
}

.container-fluid.main-container {
  max-width: 1440px;
}

* {
  font-size: 1.1rem;
}

.remove-ingredients-trash {
  font-size: 2rem !important;
  color: red;
}

.prescription_details {
  margin-top: 2em;
}

#compounding_submit:disabled {
  cursor: not-allowed;
}

ul.ui-autocomplete {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  border: solid 1px #999;
  cursor: default;
  li {
    background-color: #fff;
    border-top: solid 1px #ddd;
    margin: 0;
    padding: 0;
    a {
      color: #000;
      display: block;
      padding: 3px;
    }
    a.ui-state-hover,
    a.ui-state-active {
      background-color: #fffcb2;
    }
  }
}

.ui-menu.ui-autocomplete {
  z-index: 9999;
}

.red-border {
  border: solid red 1px !important;
}

.connect-tabs-parent {
  z-index: 1;
}

@media (min-width: 768px) {
  .doctor_order_grid_button.bottom:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 90%;
    top: 5px;
    border: 10px solid transparent;
    border-left: 10px solid #007bff;
  }
  .order_grid_button.bottom:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: 12px;
    border: 10px solid transparent;
    border-left: 10px solid #007bff;
  }
}

.passcode-field {
  width: 200px !important;
}

.tooltipster-sidetip.tooltipster-noir.tooltipster-noir-customized {
  .tooltipster-box {
    background: white;
    border: 1px solid black;
    border-radius: 0;
  }
}

.pharmacists_order_items {
  max-width: 100% !important;
  overflow-x: hidden;

  .pharmacists-table {
    margin-left: -34px;
    margin-right: -35px;
  }

  .fixed-content {
    width: 1440px;
    margin: 0 auto;
  }

  .container-fluid.main-container {
    max-width: 100% !important;
  }
}

.wice-grid ul.pagination {
  li {
    padding: 0px 10px;
  }
  li.active {
    text-decoration: underline;
    font-weight: bold;
  }
}

.spree_doctor_orders {
  .mobile-element-detail {
    color: #000;
  }
}

@media (max-width: 768px) {
  .custom-table {
    position: static !important;
  }

  .custom-table .col-sm-12 {
    position: static !important;
  }
}

.subscription-btn,
.subscription-btn:hover {
  color: #212529;
}

@media (min-width: 1300px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 767px) {
  .full-width a {
    width: 100%;
    margin: 5px 0;
  }

  .d-flex.flex-row {
    width: 90px;
  }

  .space-10 {
    display: inline-block;
    width: 100%;
    padding: 0 0 4px 0;
  }

  .btn-primary,
  .btn-danger,
  .btn-dark,
  .btn-success,
  .btn-default {
    width: 100%;
    margin-bottom: 5px;
    margin-right: 2px;
  }

  .btn-default {
    margin-left: 0;
  }

  .page-link {
    padding: 0.5rem 0.7rem;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .container-fluid nav .pagination {
    width: 500px;
  }

  .container-fluid nav {
    overflow: auto;
  }

  .bold {
    word-break: break-all;
  }

  #dashboard-header .col-md-12 {
    padding: 0;
  }

  #dashboard-header .col-md-12 .navbar {
    padding: 0;
  }

  #dashboard-header .col-md-12 .bg-transparent {
    float: left;
  }

  .header-profile.navbar-item {
    padding-left: 18px;
    padding-right: 1px;
  }

  .header-profile .logout-nav {
    margin-right: 10px;
    margin-left: 5px;
  }

  #dashboard-header .navbar {
    box-shadow: none;
    padding: 0;
  }

  .print-media-scr .navbar-expand-md .navbar-header .navbar-collapse {
    position: absolute;
    top: 22px;
    background: red !important;
    width: 100%;
    z-index: 99999;
    left: 0;
    right: 0;
  }

  .row.print-media-scr {
    .main-nav.navbar.navbar-dark.navbar-expand-md {
      width: 60px;
    }
  }
}

@media (max-width: 480px) {
  .full-wid {
    width: 100%;
  }
}

.red-counter {
  color: red;
}

.text-purple {
  color: purple;
}

.mirrorImage {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.w-auto {
  width: auto;
}
