.rugiet .tooltipster-box {
	border-radius: 5px;
	border: 1;
	background: #F6EBB8;
	border-color: #ecc817;
}

.rugiet .tooltipster-content {
	color: black;
}

.rugiet .tooltipster-arrow {
	height: 11px;;
	margin-left: -11px;
	width: 22px;
}

.rugiet.tooltipster-left .tooltipster-arrow,
.rugiet.tooltipster-right .tooltipster-arrow {
	height: 22px;
	margin-left: 0;
	margin-top: -11px;
	width: 11px;
}

.rugiet .tooltipster-arrow-background {
	border: 11px solid transparent;
}

.rugiet.tooltipster-bottom .tooltipster-arrow-background {
	border-bottom-color: #F6EBB8;
	top: 4px;
}

.rugiet.tooltipster-left .tooltipster-arrow-background {
	border-left-color: #F6EBB8;
	left: -4px;
}

.rugiet.tooltipster-right .tooltipster-arrow-background {
	border-right-color: #F6EBB8;
	left: 4px;
}

.rugiet.tooltipster-top .tooltipster-arrow-background {
	border-top-color: #F6EBB8;
	top: -4px;
}

.rugiet .tooltipster-arrow-border {
	border-width: 11px;
}

.rugiet.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: #ecc817;
}

.rugiet.tooltipster-right .tooltipster-arrow-border {
	border-right-color: #F6EBB8;
}

.rugiet.tooltipster-top .tooltipster-arrow-border {
	border-top-color: #F6EBB8;
}

.rugiet.tooltipster-left .tooltipster-arrow-border {
	border-left-color: #F6EBB8;
}

.rugiet.tooltipster-bottom .tooltipster-arrow-uncropped {
	top: -11px;
}

.rugiet.tooltipster-right .tooltipster-arrow-uncropped {
	left: -11px;
}
