
@media (max-width: 992px) {
  .padding-left-20 {
    padding-left: 0px !important;
  }

  .doctor-dashboard {
  	font-size: 24px;
  }

  .margin-bottom-20-md {
    margin-bottom: 20px;
  }

  .size-24 input {
    font-size: 15px;
  }

  .font-size-15 {
  	font-size: 15px;
  }

  .pull-left p {
  	margin-bottom: 6px !important;
  }

  .hidden {
  	display: none;
  }

  .top-pics {
    font-size: 20px;
  }

  .signature_pad_body {
    width: 300px !important;
  }

  .full-width input{
    width: 100% !important;
  }

  .users.edit {
    td.d-flex.flex-column {

      a {
        max-width: 300px;
      }
    }
  }

}

@media (max-width: 576px) {
  .align-items-center {
    padding-right: 40px;
  }

  .signature_pad_body {
    width: 200px !important;
  }

  .margin-left-15 {
    margin-left: 15px;
  }

  .prescription.col-12 {
    flex: 0 0 100% !important;
  }

  .hide-small-screen {
    display: none;
  }

  .left-right-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .margin-left-20-sm {
    margin-left: 20px;
  }

  .right {
    float: left !important;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .users.edit {
    td.d-flex.flex-column {

      a {
        max-width: 200px;
      }
    }
  }

}

.custom-modal-doctor-note {
  form {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .custom-modal-doctor-note {
    form {
      padding: 15px;
    }

   .d-flex {
      display: block !important;
    }

   .passcode-field {
      width: 100% !important;
      height: 40px;
    }

    .align-items-baseline.d-flex {
      margin: 2px;
    }

    .margin-bottom-20 {
      margin-bottom: 0px;
    }

    .text-muted {
      margin-bottom: 12px;
    }

    input.btn-primary {
      margin-bottom: 10px;
    }
  }

  #modal-window {
    .modal-content {
      padding: 24px;
      .h2.text-center {
        font-size: 24px;
      }

      .space-10 {
        margin-top: 15px;
      }
    }
  }

  .nested-fields {
    .col-1 {
      padding-left: 0;
    }

    .col-5 {
      padding-left: 0;
    }
  }

  .dashboard-container .px-4 {
    padding: 0 !important;
  }

  .links {
    width: 100%;
    margin-top: 8px;
  }

  #modal-window .space-20 {
    padding: 0;
  }

  #new_spree_comment {
    margin-top: 12px;
  }

  .signature_pad_body.doctor-signature {
    width: 100% !important;
    canvas {
      width: 100% !important;
    }
  }

  .dashboard-container {
    padding: 8px;
  }

  .modal.fade.show:after {
    content: "";
    background: #00000091;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .d-print-none {
    width: 100%;
    margin-left: -2px;
    margin-top: 5px;
    padding: 7px 0 7px 0 !important;
  }

  .form-group .remove-ingredients-trash {
    font-size: 1.4rem !important;
  }
}
