@import 'select2/dist/css/select2.min.css';

$light-green: #657589;

$elan-blue: #4d6279;
$catskill-white: #eff3f7;
$wild-sand: #f2f4f7;
$geyser: #d8dee8;
$celeste: #cccccc;
$white: #ffffff;
$regent-gray: #808d9e;
$slate-gray: #708193;
$alto: #dddddd;
$surf-crest: #d6e3d3;

.header {
  .top-bar {
    background-color: #003300;
  }
}

.header-profile {
  padding: 18px;
  span.profile-name {
    padding-left: 8px;
    a {
      color: $geyser;
    }
  }
}

.tab-inactive,
.tab-inactive a {
  background-color: #98aab9;
  padding: 5px 12px;
  margin: 2px;
  color: black;
}

.tab-active,
.tab-active > a,
.tab-active > a:focus,
.tab-active > a:hover {
  border-color: #1558e7;
  background-color: #98aab9;
  color: #fff;
  padding: 5px 12px;
  text-decoration: none;
}

.pills {
  padding: 4px 10px;
  border-radius: 25px;
  border: 1px solid #2b4969;
}

.pill-active {
  border: none;
  cursor: pointer;
  color: blue;
}
.well-custom {
  padding: 25px 0px !important;
}
.nav-tabs-custom {
  border: none !important;
}
.col-custom {
  padding: 0 !important;
}
.custom-nav {
  padding: 6px 20px !important;

  margin: 5px !important;

  position: relative !important;

  top: -22px !important;
}

.custom-link {
  text-decoration: none;
  outline: none;
}

.custom-link:hover {
  text-decoration: none;
}

.input-group .input-group-addon {
  border-radius: 0;
  border-color: #d2d6de;
  background-color: #fff;
}

.page-content {
  .container-fluid {
    background-color: #f0f2f6;
    height: auto;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0px;
    font-family: "gotha-medium";
    padding: 0;
    form {
      h4 {
        color: #8c98a7;
      }
      label {
        color: $light-green;
        text-align: left;
      }
    }
  }
}

#navbar-supported-content {
  ul li {
    margin-left: 5px;
    margin-right: 5px;
  }
  a {
    color: white;
  }
  span:hover {
    cursor: pointer;
    color: Red;
  }
}

.print-panel {
  width: 288px;
  height: 192px;
  padding: 50px;
}

.table thead tr th {
  font-weight: 200;
  background-color: #fefefe;
  border-left: 0px;
  border-right: none;
  font-family: "gotham-bold";
}

.single-item {
  width: 100%;

  height: 55px;

  background-color: #0c0cf866;

  text-align: center;

  margin: 10px;
}
.single-item:hover {
  background: #2a4969;
}
.image-list {
  color: white !important;
  letter-spacing: 1px;
  text-transform: capitalize;
  display: inline-block;
  padding-top: 15px;
}
.img-cover {
  border-radius: 3px;
}
body #sidebar .sidebar-header {
  padding: 0 !important;
  background: #0f1941;
}

.navbar.main-nav {
  &,
  .dropdown-menu {
    background-color: #0074d1;
  }

  .dropdown-item:hover {
    background-color: #003300;
  }
}

nav.main-nav .container ul li a.active {
  background-color: #0074d1 !important;
}
nav.main-nav .container ul li ul {
  background-color: #0074d1 !important;
}
.logout {
  position: fixed;
  right: 20px;
  font-size: 35px;
  top: 15px;
}

label {
  font-weight: 200;
}
@media only screen and (max-width: 767px) {
  .logout {
    position: relative;
    right: 0;
    top: 15px;

    font-size: x-large;
  }
}
h4 {
  font-size: 20px;

  letter-spacing: 1px !important;
}
.heading {
  font-size: 20px;

  letter-spacing: 1px;
}

.filter {
  font-size: 30px !important;
  font-weight: 400 !important;
}
.select-filter {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  text-align: left !important;
  width: auto !important;
}

#content {
  #breadcrumb-navigator {
    margin-bottom: 1rem;
    font-size: 28px;
  }
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -49px;
  position: relative;
  z-index: 2;
}

.logo {
  padding: 20px 0 20px 20px;
}

#medication_medication_category_id_chosen {
  border: none !important;
  border-bottom: 2px solid #2b49694d !important;
  outline: none;
  width: 100% !important;
  margin-left: -7px;
  maring-top: 2px;
}

.invalid-feedback {
  color: red;
}

.custom-button {
  height: 51px;
  word-wrap: break-word;
  white-space: normal;
}

.col-expiration {
  width: 175px !important;
}

.col-trashcan {
  display: flex;
  width: 40px !important;
  height: 83.2px;
  align-items: center;
  margin-top: 10px;
}

.col-hide-checkbox {
  width: 160px;
  align-self: center;
  margin-top: 10px;
  margin-left: 10px;
}

.col-checkbox {
  width: 185px;
  align-self: center;
  margin-top: 10px;
}
.space-20 {
  padding: 20px;
}

.space-30 {
  padding: 30px;
}

.space-10 {
  padding: 10px;
}

.space-2 {
  padding: 2px;
}

.space-5 {
  padding: 5px;
}

.mar-top-20 {
  margin-top: 20px;
}

.mar-top-10 {
  margin-top: 10px;
}

.mar-top-5 {
  margin-top: 5px;
}

.mar-left-3 {
  margin-left: 3px;
}

.mar-down-10 {
  margin-bottom: 10px;
}

body {
  .pills-container {
    .btn-round-lg {
      border-radius: 40px;
    }
  }
}

.inner-addon {
  position: relative;
}

.inner-addon .fa-search {
  position: absolute;
  right: 30px;
  top: 9px;
  cursor: pointer;
  color: grey;
}

.right-addon .fa {
  right: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

.rounded_input {
  border-radius: 15px;
  text-align: center;
}

.navbar-collapse .navbar-nav li a {
  color: #d3d3d3;
}

.top-header {
  background-color: #0033cc;
  padding: 1px;
}
.dot {
  color: #fff;
  padding: 5px;
  margin-left: 20px;
  font-size: 16px;
}

.welcome {
  text-align: center;
  margin-top: 25px;
  font-size: 25px;
  font-weight: bold;
}

hr {
  height: 3px;
  color: blue;
  background-color: gray;
  border: none;
}

.box-review {
  border: 2px solid black;
  padding: 10px;
  border-radius: 5px;
}

.print-box-review {
  border-radius: 10px !important;
  border: 1px;
  padding: 10px;
  width: 3in;
  height: 5in;
  text-align: center;
}

.payment-profile-box {
  border: 2px solid;
  height: 100px;
  width: 200px;
}

.discontinue-stamp {
  border: 3px solid red;
  height: 60px;
  width: 200px;
  text-align: center;
  color: red;
  border-radius: 4px;
  transform: rotate(-12deg);
  position: absolute;
}

.declined-stamp {
  border: 3px solid purple;
  height: 70px;
  width: 200px;
  text-align: center;
  color: purple;
  border-radius: 4px;
  transform: rotate(-12deg);
  position: absolute;
}

.expiring-stamp, .no-refills-stamp {
  border: 3px solid orange;
  height: 60px;
  width: 200px;
  text-align: center;
  color: orange;
  border-radius: 4px;
  transform: rotate(-12deg);
  position: absolute;
  h4.expiring {
    margin-top: 18px;
  }
  h4.no-refills {
    margin-top: 9px;
  }
}

.login-form {
  background-color: #f0f2f6;
  height: auto;
}

.login {
  font-size: 20px;
  padding: 10px;
}

.Forgotloginpage-1 {
  float: right;
  text-decoration: none;
}

.Forgotloginpage-2 {
  font-size: 14px;
  text-decoration: none;
  margin-left: 25px;
}

.SignUp {
  font-size: 14px;
  text-decoration: none;
  float: right;
}

.stripe-button-el {
  width: 100%;
  background-image: none !important;
  background-color: #337ab7 !important;
  background: #5aa0f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #8abdfb1a;
  border-radius: 4px;
  opacity: 1;
}

.stripe-button-el span {
  background-image: none !important;
  background: #337ab7 !important;
  background-color: #337ab7 !important;
}

.simple-calendar {
  .has-events {
    background-color: #fa3e3e;
    ul {
      display: block;
      list-style-type: disc;
      margin-top: 1em;
      margin-bottom: 1 em;
      margin-left: 0;
      margin-right: 0;
      padding-left: 10px;
    }
  }
  .prev-month {
    background-color: #f1f1f1;
    color: black;
  }
  .next-month {
  }
}

.modal-content {
  background: $geyser;
}

.subhead {
  font-size: 66%;
}

#login {
  margin-bottom: 150px;
  margin-top: 150px;
  background-color: #f0f2f6;
}

.login-heading {
  font-family: "gotham-bold";
  font-size: 25px;
  margin: 10px;
  margin-bottom: 20px;
}

.login-text {
  font-family: "gotham-light";
  font-size: 12px;
  margin: 10px;
  text-align: center;
  padding-top: 20px;
  span {
    font-family: "gotham-bold";
  }
}

#artwork_image img {
  height: 650px;
}

.content-body {
  border-radius: 24px 24px 0px 0px;
  background-color: #fefefe;
}

.doctor-dashboard {
  font-family: "gotham-bold";
  font-size: 30px;
  color: #a6b3c4;
  opacity: 0.6;
}

.top-pics {
  font-family: "gotham-medium";
  font-size: 24px;
  color: #18182f;
  opacity: 1;
  padding: 10px;
  padding-left: 20px;
}

.bold {
  font-family: "gotham-bold";
}

.table tbody tr td {
  background-color: #ffffff;
  border-left: 0px;
  border-right: none;
}

.dashboard-container {
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 0px -6px 20px #0065b417;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  margin-bottom: 20px;
}

.modal-content .dashboard-container {
  box-shadow: none;
}

.padding-left-20 {
  padding-left: 20px;
}

.dashboard-textfield input {
  font-size: 15px;
  font-family: "gotham-light";
  color: #18182f;
  opacity: 1;
  width: 100%;
  border-radius: 6px 6px 6px 6px;
}

.no-border input {
  border: none;
}

.fa-pencil {
  color: black;
}

.btn-sm {
  background-color: #337ab7 !important;
  background: #5aa0f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #8abdfb1a;
  border-radius: 4px;
  opacity: 1;
}

.order_grid_button {
  min-height: 45px;
  min-width: 70px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.9;
    outline: 1px solid $elan-blue;
  }
}

.size-24 input {
  font-size: 24px;
}

th {
  width: 100px;
}

.logout-nav {
  .fa-cog {
    margin-right: 10px;
  }
  .fa-cog,
  .fa-sign-out-alt {
    color: #fff !important;
    font-size: 18px !important;
  }
}

.rounded-container {
  margin-top: -15px;
  background: #0074d1;
}

.new-prescription-textfield input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  opacity: 1;
}

.form-control:disabled {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  cursor: not-allowed;
}

.btn-default {
  border: 1px solid #cbcbcb;
}

.margin-right-20 {
  margin-right: 20px;
}

.new-presription-box {
  width: 100px;
  height: 100px;
  background: #ffffff00 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 20px #1ad13f0f;
  border: 1px solid #0782e5;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
}

.plus {
  border-radius: 50%;
  width: 57px;
  height: 57px;
  background-color: #ffffff00 0% 0% no-repeat padding-box;
  position: relative;
}
.plus::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: #0e8df2;
  height: 2px;
  margin-top: -3px;
  top: 50%;
  left: 5px;
  right: 5px;
  z-index: 9;
}
.plus::before {
  content: " ";
  position: absolute;
  display: block;
  background-color: #0e8df2;
  width: 2px;
  margin-left: -3px;
  left: 50%;
  top: 5px;
  bottom: 5px;
  z-index: 9;
}

.light {
  font-family: "gotham-light";
}

hr {
  height: 1px;
}

.form-control-plaintext {
  font-family: "gotham-bold";
}

.modal-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 30px #3838670f;
  border-radius: 24px;
  opacity: 1;
}

.modal-dialog {
  max-width: 600px !important;
}

.orders.show {
  .modal-dialog {
    max-width: 1440px !important;
  }
}

.user-body {
  background-color: #fefefe;
  padding-right: 0px;
  padding-left: 0px;
}

.bootstrap-table .table {
  border: none;
}

.fixed-table-container {
  border: none;
}

.btn-default {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0a89ef;
  border-radius: 6px;
  opacity: 1;
  margin-left: 5px;
  border-radius: 4px;
}

.btn-primary {
  background: #5aa0f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #8abdfb1a;
  border-radius: 4px;
  opacity: 1;
}

.black-font a {
  color: black;
  margin-left: -10px;
  margin-top: 10px;
}

.black-font {
  color: black;

  a {
    margin-left: -10px;
    margin-top: 10px;
  }
}

.inner-addon .fa-search {
  color: #5aa0f5;
}
.fixed-table-container thead th {
  border-top: none;
}

.fixed-table-container thead th .asc,
.desc {
  background-image: none !important;
}

.fixed-table-container thead th a.sort_link {
  color: black;
}

.table-bordered thead th .asc,
.desc {
  background-image: none !important;
}

.table-bordered thead th a.sort_link {
  color: black;
}

.search input {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 20px #3838670f;
  border: none;
  opacity: 1;
}

$tm_alert: red;
$tm_flag_do_not_prescribe: purple;
$tm_flag_high: red;
$tm_flag_medium: #e6ac00;
$tm_flag_low: green;
$tm_flag_lowest: blue;

.tm_flag_do_not_prescribe {
  color: $tm_flag_do_not_prescribe;
  font-weight: bold;
}

.tm_flag_high {
  color: $tm_flag_high;
  font-weight: bold;
}

.tm_flag_medium {
  color: $tm_flag_medium;
}

.tm_flag_low {
  color: $tm_flag_low;
}

.tm_flag_lowest {
  color: $tm_flag_lowest;
}

.tm_flag_bg {
  color: white;
}

.tm_flag_bg_do_not_prescribe {
  background: $tm_flag_do_not_prescribe;
}

.tm_flag_bg_high {
  background: $tm_flag_high;
}

.tm_flag_bg_medium {
  background: $tm_flag_medium;
}

.tm_flag_bg_low {
  background: $tm_flag_low;
}

.tm_flag_bg_lowest {
  background: $tm_flag_low;
}

.tm_flag_bg_none {
  background: white;
  color: black;
}

.tm_alert {
  color: $tm_alert;
}

.allergies_flag {
  font-weight: bold !important;
  color: $tm_flag_lowest;
}

.patient-profile img {
  border-radius: 50%;
  margin: -1px;
  overflow: hidden;
  position: relative;
  height: 100px;
  width: 100px;
}

.patient-tab a {
  color: #cbcbcb;
}

.patient-tab .active {
  color: #5aa0f5;
}

.color-blue .fa-pencil {
  color: #5aa0f5;
}

.medium a {
  width: 110px;
  font-family: "gotham-medium";
  font-size: 12px;
  color: #18182f;
  border-radius: 6px;
}

.medium .btn-primary {
  background: #0074d1 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.left-right-20 {
  margin-left: -20px;
}

hr {
  background-color: #aba6a6;
}

.right {
  float: right !important;
}

.padding-bottom-300 {
  padding-bottom: 300px;
}

.not_bold {
  font-weight: normal !important;
}

.sleeve {
  table th,
  table td {
    border: 1px transparent !important;
  }
}

.pt11 {
  font-size: 16px;
}

.pt8 {
  font-size: 11px !important;
}

.pt14 {
  font-size: 18px;
}

body.orders.show,
body.pharmacists_patients.show,
body.orders.begin_consultation {
  .id-images {
    .id-help {
      max-width: 300px;
    }
    .id-block {
      display: block;
      max-width: 300px;
      height: 200px;
      border: 1px solid #d2d6de;
      padding: 5px;
      img {
        display: block;
        max-height: 100%;
        margin: auto;
      }
    }
  }
}

.carrier-shipment-status {
  margin-right: 5px;

  > circle {
    stroke: #0f0f0f;
    stroke-width: 1px;

    &.green-status {
      fill: #129b12;
    }

    &.yellow-status {
      fill: #e5e506;
    }

    &.red-status {
      fill: #dd1717;
    }
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.border {
  border-width: 2px !important;
}

.border-bottom {
  border-bottom-width: 2px !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.inline * {
  display: inline-block;
}

.cp-product {
  h1 {
    font-family: "gotham-bold";
  }
  p {
    font-weight: 500;
    font-family: "gotham-medium";
  }
}

.table-container {
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.cp-heading {
  font-family: "gotham-bold";
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #003300;
  }
}

.consultation-billing-dashboard {
  @media (min-width: 767px) {
    margin-top: -66px;
    pointer-events: none;
  }
  .view-reports {
    pointer-events: auto;
    color: #fff;
  }
}

.patient-messages, .patient-photos-record {
  img {
    height: 100px;
    width: 100px;
    margin: 5px;
  }
}
