body.billings.new {
  #checkout-button {
    background: #5AA0F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #8ABDFB1A;
    opacity: 1;
    color: #FFFFFF !important;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border: 1px solid #007bff;
    width: 100%;
  }
}
