// Place all the styles related to the users controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.users.edit, .users.update {
  h3 {
    margin-top: 1em;
  }

  td.d-flex.flex-column {

    a {
      max-width: 300px;
    }
  }

  th {
    width: 30%;
  }

  .btn-sm.cancel-btn {
    background-color: red !important;
  }

}
