.dashboards.index {
  .even-table th,
  .even-table td {
    width: 20%;

    .btn {
      width: 100%;
    }
  }

  .pharmacists-dashboard {
    .even-table th,
    .even-table td {
      width: calc(100%/7) !important;
      word-break: break-word;
    }

  .pharmacists-dashboard {
    .total-orders-table th,
    .total-orders-table td {
      width: calc(100%/13) !important;
      word-break: break-word;
    }
  }

    #eight-columns th,
    #eight-columns td {
      width: calc(100%/9) !important;
      word-break: break-word;
      .btn {
        width: 100%;
      }
    }

  }

  .billing-summary tr {
    td:first-child {
      font-weight: bold;
      text-align: left;
    }

    td:nth-child(2) {
       font-weight: bold;
       text-align: right;
     }
  }

  td.form-td {
    width: 40%;

    form {
      display: flex;

      select {
        width: 50%;
        height: 43px;
      }

      .full-width {
        width: 50%;
      }
    }
  }
}
