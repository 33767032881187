body.message_templates {
  h4 {
    padding-top: 1em;
    margin-left: 1em;
  }

  .dashboard-container > a {
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .table-wrapper {
    margin-top: 1em;

    td.action_buttons {
      :first-child {
          margin-left: 1em;
      }
    }
  }

  .form {
    padding-bottom: 5em;
  }

  .form * {
    margin-top: 1em;

    input.btn {
      margin-left: 1em;
    }
  }

  @media only screen and (max-width: 768px) {
    .table-wrapper {
      .mobile-hidden {
        display: none;
      }
    }
  }
}
