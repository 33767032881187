body.compounding_logs.new {
  label {
    font-weight: bold !important;
    text-transform: capitalize;
  }
  .trash {
    padding-top: 2em;
  }

  @media (min-width : 768px)
  and (max-width : 1024px) {
    .nested-fields label {
      min-height: 4em;
    }
  }
}
